import React from "react";
import { Zoom } from "react-reveal";

const SectionHeader = ({ text, light }) => {
  return (
    <header className={`section_header ${light && "light"}`}>
      <Zoom cascade>
        <h1>{text}</h1>
      </Zoom>
      <h1 className="backdrop">{text}</h1>
    </header>
  );
};

export default SectionHeader;
